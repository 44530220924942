@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-sans;
  }

.calendar-custom .p-datepicker {
    background:#ece5df;
    color: #1f2937;
}

.calendar-custom .p-datepicker-header {
    background:#ece5df;
    color: #1f2937;
}

.calendar-custom .p-datepicker-calendar td span {
    color: #1f2937;
}

.calendar-custom .p-datepicker-calendar td {
    border-radius: 50%;
}

.day-past::before {
    position: relative;

}
.day-past::after {
    content: 'X';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(104, 19, 19);
    font-size: 1.5rem;
}


.flag-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    box-shadow: none;
    outline: none;
}

.flag-icon-gb {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/gb.svg');
}

.flag-icon-kw {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/kw.svg');
}

/* إزالة التأثيرات عند التركيز أو التفاعل */
.p-button-text:focus, .p-button-text:active {
    outline: none;
    box-shadow: none;
}


  .custom-dataview .p-dataview-content,
  .custom-dataview .p-dataview-header,
  .custom-dataview .p-paginator {
    background: #1e1e1e !important;
      color: white;
      border-radius: 10px;  
    }


    .custom-file-upload .p-fileupload-buttonbar {
       background: #1e1e1e !important;
        color: white;
        border-radius: 0.375rem; /* لتطابق الزوايا المدورة لباقي المكونات */
    }
    
    .custom-file-upload .p-button {
       background: #1e1e1e !important;
        border-color: transparent !important;
        color: white !important;
    }
    .custom-file-upload .p-fileupload-content {
        background: #1e1e1e !important;
        color: white !important;
        border-radius: 0.375rem; /* مطابقة الزوايا المدورة لباقي المكونات */
    }
    
    

    .custom-placeholder::placeholder {
        color: white; /* تغيير لون نص الـ placeholder إلى الأسود */
        opacity: 1; /* يجعل اللون الأسود يظهر بشكل واضح */
    }
    

    @media (max-width: 768px) {
        .p-dataview-layout-options {
            display: none;
        }
    }
    

    .custom-grido {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* الوضع الافتراضي للهواتف */
        gap: 1.5rem; /* لضبط الفجوات بين العناصر */
    }
    
    @media (min-width: 768px) {
        .custom-grido {
            grid-template-columns: repeat(4, 1fr); /* الوضع المتوسط */
        }
    }
    
    @media (min-width: 1024px) {
        .custom-grido {
            grid-template-columns: repeat(5, 1fr); /* وضع الكمبيوتر */
        }
    }
    

    .custom-gridoo {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* الوضع الافتراضي للهواتف */
        gap: 1.5rem; /* لضبط الفجوات بين العناصر */
    }
    
    @media (min-width: 768px) {
        .custom-gridoo {
            grid-template-columns: repeat(3, 1fr); /* الوضع المتوسط */
        }
    }
    
    @media (min-width: 1024px) {
        .custom-gridoo {
            grid-template-columns: repeat(3, 1fr); /* وضع الكمبيوتر */
        }
    }


    .custom-gridd {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
    
    .card {
        background: linear-gradient(to right, #b7b7a4, #a5a58d, #6b705c);
        border-radius: 1rem;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        text-align: center;
        transition: transform 0.3s ease-in-out;
        cursor: pointer; /* تغيير المؤشر عند المرور فوق الكرت */
    }
    
    .card:hover {
        transform: scale(1.05);
    }
    
    .title {
        font-size: 14px; /* حجم النص الثابت */
        margin-bottom: 8px;
        color: #000000; /* اللون الثابت للنص */

        font-weight: bold; /* جعل النص بخط غامق */

        
    }
    
    .image {
        object-fit: cover;
        margin-bottom: 8px;
        border-radius: 0.5rem; /* إضافة الزوايا الدائرية للصورة */
    }
    
    @media (max-width: 640px) {
        .custom-gridd {
            gap: 0.5rem; /* تقليل المسافة بين الكروت في وضع الهاتف */
        }
    
        .card {
            width: 120px; /* ضبط العرض الثابت للكروت في وضع الهاتف */
            height: 135px; /* ضبط الارتفاع الثابت للكروت في وضع الهاتف */
        }
    
        .image {
            width: 100px; /* عرض الصورة الثابت في وضع الهاتف */
            height: 80px; /* ارتفاع الصورة الثابت في وضع الهاتف */
        }
    }
    
    @media (min-width: 640px) {
        .custom-gridd {
            grid-template-columns: repeat(3, 1fr);
        }
    
        .card {
            width: 150px; /* ضبط العرض الثابت للكروت للأجهزة اللوحية */
            height: 150px; /* ضبط الارتفاع الثابت للكروت للأجهزة اللوحية */
        }
    
        .image {
            width: 120px; /* عرض الصورة الثابت للأجهزة اللوحية */
            height: 100px; /* ارتفاع الصورة الثابت للأجهزة اللوحية */
        }
    }
    
    @media (min-width: 768px) {
        .custom-gridd {
            grid-template-columns: repeat(3, 1fr);
        }
    
        .card {
            width: 180px; /* ضبط العرض الثابت للكروت للأجهزة الأكبر */
            height: 180px; /* ضبط الارتفاع الثابت للكروت للأجهزة الأكبر */
        }
    
        .image {
            width: 150px; /* عرض الصورة الثابت للأجهزة الأكبر */
            height: 120px; /* ارتفاع الصورة الثابت للأجهزة الأكبر */
        }
    }
    
    @media (min-width: 1024px) {
        .custom-gridd {
            grid-template-columns: repeat(3, 1fr);
        }
    
        .card {
            width: 400px; /* ضبط العرض الثابت للكروت للأجهزة الكبيرة */
            height: 400px; /* ضبط الارتفاع الثابت للكروت للأجهزة الكبيرة */
        }
    
        .image {
            width: 370px; /* عرض الصورة الثابت للأجهزة الكبيرة */
            height: 340px; /* ارتفاع الصورة الثابت للأجهزة الكبيرة */
        }
    }
    

    /* MakeUpArtistPage.css */
.custom-griddd {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

@media (min-width: 640px) {
    .custom-griddd {
        grid-template-columns: repeat(3, 1fr); /* ثلاثة أعمدة في وضع الهاتف */
    }
}

@media (min-width: 768px) {
    .custom-griddd {
        grid-template-columns: repeat(3, 1fr); /* ثلاثة أعمدة للشاشات المتوسطة */
    }
}

@media (min-width: 1024px) {
    .custom-griddd {
        grid-template-columns: repeat(4, 1fr); /* أربعة أعمدة للشاشات الكبيرة */
    }
}












  .cardd {
    background: linear-gradient(to right, #b7b7a4, #a5a58d, #6b705c);
    border-radius: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }

  .cardd:hover {
    transform: scale(1.05);
  }

  .titlee {
    font-size: 12px; /* حجم النص الثابت */
    margin-bottom: 2px;
    color: #000000; /* اللون الثابت للنص */
    font-weight: bold; /* جعل النص بخط غامق */
  }

  .imagee {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 8px;
    border-radius: 0.5rem; /* إضافة الزوايا الدائرية للصورة */
  }



